import React from 'react'
import { Headline } from '@liquid-design/liquid-design-react'
import LayoutLegal from 'layouts/LayoutLegal/LayoutLegal'
import ScrollableTable from 'components/ScrollableTable/ScrollableTable'

export default function Privacy() {
  return (
    <LayoutLegal>
      <div className="stack mb-20">
        <Headline type="H1" className="pb-10">
          Data Privacy Declaration
        </Headline>
        <Headline type="H2">1. General information</Headline>
        <p>
          Thank you for your interest in the website(s) of EMD Digital Inc., an
          affiliate of Merck KGaA, Darmstadt, Germany, hereinafter referred to
          as "EMD Digital". We take data protection and privacy issues very
          seriously and comply with the applicable national and European data
          protection regulations. Therefore, we would like to inform you with
          this declaration about data protection measures and which data we may
          store and how we use this data.
        </p>
        <Headline type="H2">
          2. What kind of data is collected and how is it used?
        </Headline>
        <Headline type="H3">2.1 Data collection by this website</Headline>
        <p>
          Whenever a user accesses the website, the user’s internet browser
          automatically transfers the following data to EMD Digital’s web server
          for technical reasons:
        </p>
        <ul>
          <li>IP address of the requesting computer</li>
          <li>date and time of access</li>
          <li>name and URL of the retrieved file</li>
          <li>transferred data volume</li>
          <li>access status (file transferred, file not found etc.)</li>
          <li>identification data of the browser and operating system used</li>
          <li>name of the provider of user's internet access</li>
          <li>website from which access is made</li>
        </ul>
        <p>
          This data is collected, processed and used for the purpose of enabling
          the use of the website (connection setup), system security and
          technical administration of the network infrastructure. A comparison
          with other databases or a transfer to third parties, also in excerpts,
          does not take place.
        </p>
        <p>
          The data collected as part of the voluntary entries by contacting EMD
          Digital through{' '}
          <a
            className="font-extrabold text-vibrant-cyan hover:text-vibrant-cyan-dark hover:underline"
            href="mailto:support@knoragene.com"
          >
            support@knoragene.com
          </a>{' '}
          or{' '}
          <a
            className="font-extrabold text-vibrant-cyan hover:text-vibrant-cyan-dark hover:underline"
            href="mailto:info@knoragene.com"
          >
            info@knoragene.com
          </a>{' '}
          will be used and processed exclusively for the purpose of processing
          the enquiry and to carry out the services you may have requested.
        </p>
        <Headline type="H3">2.2 Use of Google Analytics</Headline>
        <p>
          This website uses Google Analytics, a web analytics service provided
          by Google Inc. (“Google”). Google Analytics uses “cookies”, which are
          text files placed on your computer, to help the website analyze how
          users use the site. The information generated by the cookie about your
          use of this website is usually transferred to a Google server in the
          USA and stored there. However, if you activate the IP anonymization on
          this website, your IP address will be shortened previously by Google
          within Member States of the European Union or in other contracting
          states of the Agreement on the European Economic Area. Only in
          exceptional cases the full IP address is transferred to a Google
          server in the USA and shortened there. On behalf of the operator of
          this website, Google will use this information to evaluate your use of
          the website, to compile reports on website activity and to provide the
          website operator with further services related to website and internet
          use. The IP address transmitted by your browser within the scope of
          Google Analytics will not be aggregated with other Google data. You
          may refuse the use of cookies by selecting the appropriate settings on
          your browser, however please note that if you do this you may not be
          able to use the full functionality of this website. You can also
          prevent Google from collecting the data generated by the cookie and
          relating to your use of the website (including your IP address) and
          from processing this data by downloading and installing the browser
          plug-in available under the following link:
          <br />
          <a
            className="font-extrabold text-vibrant-cyan hover:text-vibrant-cyan-dark hover:underline"
            href="http://tools.google.com/dlpage/gaoptout?hl=de"
          >
            http://tools.google.com/dlpage/gaoptout?hl=de
          </a>
        </p>
        <p>
          You can prevent Google Analytics from collecting data by clicking on
          the following link. An opt-out cookie is set that prevents future
          collection of your data when you click this link:{' '}
          <a
            className="text-vibrant-cyan font-black no-underline hover:underline hover:text-vibrant-cyan-dark"
            href="#analytics-opt-out"
            onClick={(event) => {
              event.preventDefault()
              document.cookie =
                'ga-optout=1; expires=Wed, 1 Jan 3000 00:00:00 UTC; path=/'
              alert('Analytics opt-out cookie set. Please reload the page.')
            }}
          >
            Tracking Optout
          </a>
        </p>
        <p>
          An opt-out cookie is set that prevents future collection of your data
          when you visit this website.
        </p>
        <p>
          For more information on terms of use and privacy, please visit{' '}
          <a
            className="font-extrabold text-vibrant-cyan hover:text-vibrant-cyan-dark hover:underline"
            href="http://www.google.com/analytics/terms/de.html"
          >
            http://www.google.com/analytics/terms/de.html
          </a>{' '}
          or{' '}
          <a
            className="font-extrabold text-vibrant-cyan hover:text-vibrant-cyan-dark hover:underline"
            href="https://www.google.de/intl/de/policies/"
          >
            https://www.google.de/intl/de/policies
          </a>
          .
        </p>
        <p>
          Please note that on the website Google Analytics has been extended by
          the code “anonymizeIp” in order to guarantee an anonymous registration
          of IP addresses (so-called IP masking).
        </p>
        <p>
          EMD Digital's authorization arises from the fact that, on the one
          hand, EMD Digital has an interest in evaluating the website data for
          purposes of website optimization and, on the other hand, a concerned
          person can reasonably foresee at the time when the personal data is
          collected and in view of the circumstances under which it is carried
          out (in particular the above-mentioned measures) that it will possibly
          be processed for this purpose.
        </p>
        <Headline id="cookies" type="H2">
          3. Which cookies are used?
        </Headline>
        <p>
          We use cookies on our website. If you do not want to take advantage of
          our cookies, you can find out in the help function of your browser how
          to set your browser to prevent it from accepting new cookies or
          deleting existing cookies. There you will also learn how to block your
          browser for all new cookies or which settings you have to make in
          order to receive a notification of new cookies.
        </p>
        <p>
          The cookies that we currently use on this website are listed in the
          cookie list that is available here:
        </p>
        <Headline type="H3">Essential Cookies (Always Active)</Headline>
        <p>
          These cookies are strictly necessary to provide you with services
          available through the Website and to use some of its features, such as
          secure login. Because these cookies are strictly necessary to deliver
          the Website, you cannot refuse them.
        </p>
        <ScrollableTable>
          <thead>
            <tr>
              <th>Name</th>
              <th>Purpose</th>
              <th>What information is collected</th>
              <th>How long is it stored</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>knora_cookies_accepted</td>
              <td>Used to flag the acceptance of cookies</td>
              <td>User Setting</td>
              <td>1 year</td>
            </tr>
            <tr>
              <td>ga_optout</td>
              <td>Used to opt out of analytics</td>
              <td>User Setting</td>
              <td>Indefinitely</td>
            </tr>
            <tr>
              <td>Session (localStorage)</td>
              <td>Maintain logged in state of user</td>
              <td>Contains identifiers for user session</td>
              <td>Indefinitely</td>
            </tr>
            <tr>
              <td>Pkce_key (localStorage)</td>
              <td>Temporary key required for OIDC authentication</td>
              <td>Random value, cannot be used for tracking</td>
              <td>Session</td>
            </tr>
            <tr>
              <td>Endpoint (localStorage)</td>
              <td>Required to bring the user back to the application</td>
              <td>URL prior to login</td>
              <td>Session</td>
            </tr>
          </tbody>
        </ScrollableTable>
        <Headline type="H3">Analytics Cookies</Headline>
        <p>
          These cookies collect information about how you use the Website, like
          which pages you visited. This information, analyzed in aggregated
          form, is used by us to understand how the Website is being used, and
          enhance your experience.
        </p>
        <ScrollableTable>
          <thead>
            <tr>
              <th>Name</th>
              <th>Purpose</th>
              <th>What information is collected</th>
              <th>How long is it stored</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_ga</td>
              <td>Used to distinguish users</td>
              <td>Randomly generated value</td>
              <td>2 years</td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>Used to distinguish users</td>
              <td>Randomly generated value</td>
              <td>24 hours</td>
            </tr>
            <tr>
              <td>_gat</td>
              <td>Used to throttle request rate</td>
              <td>Technical value</td>
              <td>1 minute</td>
            </tr>
          </tbody>
        </ScrollableTable>
        <p>
          EMD Digital's authorization arises from the fact that, on the one
          hand, EMD Digital has an interest in evaluating the website data for
          purposes of website optimization and, on the other hand, a concerned
          person can reasonably foresee at the time when the personal data is
          collected and in view of the circumstances under which it is carried
          out (in particular the above-mentioned measures) that it will possibly
          be processed for this purpose.
        </p>
        <Headline type="H2">
          4. Is other personal data collected and processed?
        </Headline>
        <p>
          We collect and process your personal data only if you request certain
          services and we need your data for this purpose or if you have
          voluntarily given us your express consent.
        </p>
        <p>
          You can do this, for example, by completing a registration form or
          sending us an email, ordering products or services, submitting
          inquiries to us, requesting materials or registering. Unless otherwise
          required by law, we will only use your personal data for the purposes
          for which you have given your consent.
        </p>
        <p>
          For special services such as newsletters, sweepstakes, etc. the
          respective special data protection provisions apply.
        </p>
        <Headline type="H2">
          5. Will my data be transferred to third parties, e.g. authorities?
        </Headline>
        <p>We pass on your personal data as set forth below.</p>
        <ul className="pl-5 list-disc list-outsinde">
          <li>
            <strong className="font-black">Insofar as required by law:</strong>
            <br />
            We may disclose your personal data if we are legally obliged to do
            so, or in cases in which this is necessary in order to respond to
            claims made against us or to comply with legal procedural
            requirements.
          </li>
          <br />
          <li>
            <strong className="font-black">To service providers:</strong>
            <br />
            We share your personal data with third party service providers who
            use this data to provide services for us, e.g. B. Payment
            processors, hosting providers, marketing technology providers,
            auditors, consultants, customer service providers or support service
            providers.
          </li>
          <br />
          <li>
            <strong className="font-black">To Merck Group companies:</strong>
            <br />
            EMD Digital may transfer your personal data to Merck Group companies
            in order to carry out a business relationship with you or for
            legitimate purposes.
          </li>
        </ul>
        <p>
          We are transferring your personal data outside the EU/EEA. We will
          take all steps reasonably necessary to ensure that appropriate
          safeguards are in place to guarantee that your personal data are
          adequately protected according to the requirements of the data
          protection laws of the European Union by means of Standard Contractual
          Clauses approved by the EU Commission.
        </p>
        <Headline type="H2">6. How long will my data be stored?</Headline>
        <p>
          We store data for as long as it is legally necessary or necessary for
          the provision of the service requested by you, or as long as it has
          been agreed upon in a declaration of consent.
        </p>
        <ul className="pl-5 list-disc list-outsinde">
          <li>
            We will erase or anonymize your personal data 2 weeks after
            termination of the respective subscription / plan or termination of
            your account. An exception applies to the extent we are subject to a
            legal obligation to store the respective personal data for a longer
            period (e.g. under applicable tax or commercial laws). For example,
            personal data in contracts, notices, business letters or invoices
            may be subject to retention periods of up to 10 years. In such case,
            we will store the respective personal data for the respective
            retention period.
          </li>
          <li>Application logs deleted after 30 days</li>
        </ul>
        <Headline type="H2">
          7. Do I have a right to information and rectification of my stored
          data? What other rights do I have with regard to my stored data?
        </Headline>
        <p>
          You may at any time and free of charge request information about the
          scope, origin and recipients of the stored data as well as the purpose
          of the storage; in addition, you have the right to rectification,
          erasure or restriction of the processing of your data in accordance
          with data protection regulations, a right to object to the processing
          as well as a right to data portability. Please note that there is a
          right of appeal to a supervisory authority.
        </p>
        <Headline type="H2">
          8. Can I withdraw my consent to the use of my data?
        </Headline>
        <p>
          You have the right to withdraw your consent to the use of your data at
          any time. Just send an email to{' '}
          <a
            className="font-extrabold text-vibrant-cyan hover:text-vibrant-cyan-dark hover:underline"
            href="mailto:info@knoragene.com"
          >
            info@knoragene.com
          </a>{' '}
          or send a letter to the following address:
        </p>
        <address className="not-italic">
          EMD Digital Inc.
          <br />
          400 Summit Dr. Burlington
          <br />
          MA 01803
          <br />
          USA
        </address>
        <p>
          The data processing performed on the basis of your consent is legal
          until the time of withdrawal.
        </p>
        <Headline type="H2">
          9. Who is my contact person if I have questions about data protection?
        </Headline>
        <p>
          If you have any questions or comments, please feel free to contact the
          Group Data Protection Officer of Merck KGaA at any time:
        </p>
        <address className="not-italic">
          Merck KGaA
          <br />
          Group Data Protection Officer
          <br />
          Frankfurter Strasse 250
          <br />
          64293 Darmstadt
          <br />
          Germany
          <br />
          <a
            className="font-extrabold text-vibrant-cyan hover:text-vibrant-cyan-dark hover:underline"
            href="mailto:privacy@emdgroup.com"
          >
            privacy@emdgroup.com
          </a>
        </address>
        <Headline type="H2">
          10. How long is this data privacy declaration valid?
        </Headline>
        <p>
          This data privacy declaration is up-to-date and dates from 12/07/2020.
          We reserve the right to amend the data privacy declaration at any time
          with effect for the future, in particular to adapt it to a further
          development of the website or the implementation of new technologies.
        </p>
      </div>
    </LayoutLegal>
  )
}
