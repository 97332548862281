import React, { ReactNode } from 'react'

import './ScrollableTable.css'

interface Props {
  ref?: React.RefObject<HTMLDivElement>
  className?: string
  style?: React.CSSProperties
  id?: string
  children?: ReactNode
}

const ScrollableTable = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const cn = `scrollable-table overflow-x-scroll min-w-full ${
      props.className || ''
    }`
    return (
      <div ref={ref} className={cn}>
        <div style={{ minWidth: '46rem' }}>
          <table className="text-left border-collapse inline-block">
            {props.children}
          </table>
        </div>
      </div>
    )
  }
)

export default ScrollableTable
